import * as React from 'react';
import {useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";

const MenuHorizontal = () => {
    const navigate = useNavigate();

    const AbrirConfiguracion = (e) => {
        e.preventDefault();
        navigate("/configuracion");
    }

    return(
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color="inherit" style={{borderBottomLeftRadius: "2em",borderBottomRightRadius: "2em"}} >
                <Toolbar variant="dense">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        BIOMETRICO FACIAL
                    </Typography>
                    <Button color="inherit" onClick={AbrirConfiguracion}>
                        <FontAwesomeIcon icon={faGears} size="lg" />
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default MenuHorizontal;