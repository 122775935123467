import React from 'react';
import {Routes,Route} from "react-router-dom";
import Home from './Home';
import Usuarios from './pages/Usuarios';
import Asistencias from './pages/Asistencias';
import Empresas from './pages/Empresas';
import Sucursales from './pages/Sucursales';
import Configuracion from './Configuracion';
import NuevoUsuario from './NuevoUsuario';
import IniciarSesion from './IniciarSesion';
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exac path="/" element={<Home/>} />
        <Route path="/iniciar/sesion" element={<IniciarSesion/>} />
        <Route path="/configuracion/usuarios" element={<Usuarios/>} />
        <Route path="/configuracion/asistencias" element={<Asistencias/>} />
        <Route path="/configuracion/empresas" element={<Empresas/>} />
        <Route path="/configuracion/sucursales" element={<Sucursales/>} />
        <Route path="/configuracion" element={<Configuracion/>} />
        <Route path="/usuario/nuevo" element={<NuevoUsuario/>} />
      </Routes>
    </div>
  );
}

export default App;
