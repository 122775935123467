import {Navigate} from "react-router-dom";
import {isAuthenticated} from "../services/Auth";

const withAuth = (Component) => {
    const AuthRoute = () => {
        if(isAuthenticated() === true){
          return <Component />;
        }else{
          return <Navigate to="/iniciar/sesion" />;
        }
    };
    return AuthRoute;
}

export default withAuth;