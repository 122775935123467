import React,{useState,useEffect} from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import DataTable from 'react-data-table-component';
import {Navigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Webcam from "react-webcam";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MenuConfiguraciones from '../components/MenuConfiguracion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import withAuth from '../components/withAuth';
import {isAuthenticated} from '../services/Auth';

const requestOptions = {
  method: 'POST',
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
    'Control-Allow-Origin': '*'
  }
};

const FiltroFecha = (d) => {
  const fecha = d.toUpperCase();
  if(fecha === "INVALID DATE"){
    return "(vacio)";
  }else{
    return fecha;
  }
};

const columnas = [
  {
    name: <div style={{fontWeight:'bold'}}>ID</div>,
    selector: row => row.Asistencia.id,
    width: "50px",
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Nombres</div>,
    selector: row => row.Usuario.nombres,
    width: "auto"
  },
  {
    name: <div style={{fontWeight:'bold'}}>Apellidos</div>,
    selector: row => row.Usuario.apellidos,
    width: "auto",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Hora Entrada</div>,
    selector: row => <Moment filter={FiltroFecha} format="DD/MM/YYYY HH:mm:ss">{row.Asistencia.hora_entrada}</Moment>,
    width: "150px",
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Hora Break</div>,
    selector: row => <Moment filter={FiltroFecha} format="DD/MM/YYYY HH:mm:ss">{row.Asistencia.hora_break}</Moment>,
    width: "150px",
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Hora Salida</div>,
    selector: row => <Moment filter={FiltroFecha} format="DD/MM/YYYY HH:mm:ss">{row.Asistencia.hora_salida}</Moment>,
    width: "150px",
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Direcci&oacute;n IP</div>,
    selector: row => row.Asistencia.ip_remota,
    width: "150px",
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Ubicaci&oacute;n</div>,
    selector: row => row.Asistencia.coordenadas,
    width: "150px",
    button: true,
  },
];

const EncabezadoTabla = () => {
  const [baseUrl,setBaseUrl] = useState("");
  const [usuarios,setUsuarios] = useState([]);
  const [usuario,setUsuario] = useState("");

  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    fetch(baseUrl+'/api/v1/usuario/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setUsuarios(data);
    });
  },[baseUrl]);

  /*
  const CambioEmpleado = (event) => {
    setUsuario(event.target.value);
  }*/

  return(
    <>
      {/*
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete disablePortal options={usuarios} getOptionLabel={(option) => `${option.identificacion} - ${option.nombres} ${option.apellidos}`} renderInput={(params) => <TextField {...params} label="Empleado" />} />
        </Grid>
        <Grid item xs={3}>
          <Button>Buscar</Button>
        </Grid>
        <Grid item xs={3}>
          <Button>Buscar</Button>
        </Grid>
      </Grid>
      */}
    </>
  );
};

const Asistencias = () => {
  const [baseUrl,setBaseUrl] = useState("");
  const [asistencias, setAsistencias] = useState([]);
  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    fetch(baseUrl+'/api/v1/asistencia/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setAsistencias(data);
    });
  },[baseUrl]);

  if (isAuthenticated() === false) return <Navigate to="/iniciar/sesion" />;
  
  return(
  <div className="container">
    <MenuConfiguraciones titulo="CONFIGURACIONES"/>
    <Box sx={{width:"100%",mt:5.5}}>
      <DataTable title="Asistencias" columns={columnas} data={asistencias} pagination subHeader subHeaderComponent={<EncabezadoTabla/>} persistTableHead/>
    </Box>
  </div>
  );  
};

export default withAuth(Asistencias);