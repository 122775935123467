import React,{useState} from 'react';
import {Navigate,useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser,faFingerprint} from "@fortawesome/free-solid-svg-icons";
import {logout,getUser,isAuthenticated} from '../services/Auth';

const MenuConfiguraciones = (props) => {
    const {titulo} = props;
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState(getUser());

    const NavegarBiometrico = (e) => {
        e.preventDefault();
        navigate("/");
    }

    const CerrarSesion = () => {
        logout();
        navigate("/");
        setUsuario(null);
    }

    if (isAuthenticated() === false) return <Navigate to="/" />;

    return(
        <Box sx={{flexGrow: 1}}>
            <AppBar position="absolute" color="inherit" >
                <Toolbar variant="dense">
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" component="div" sx={{ mr: 2 }}>
                        {titulo}
                    </Typography>
                    <Button color="inherit" href="/configuracion/usuarios">Usuarios</Button>
                    <Button color="inherit" href="/configuracion/asistencias">Asistencias</Button>
                    <Button color="inherit" href="/configuracion/turnos">Turnos</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button color="inherit" onClick={NavegarBiometrico}>
                            <FontAwesomeIcon icon={faFingerprint} size="lg" />
                        </Button>
                        <Button color="inherit" onClick={CerrarSesion}>
                            {usuario.nombres} {usuario.apellidos}
                            <FontAwesomeIcon icon={faUser} size="lg" />
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default MenuConfiguraciones;