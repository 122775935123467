import React from "react";
import Button from "@mui/material/Button";
import MuiAlert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "@mui/icons-material/Error";
import CamaraWeb from './../components/CamaraWeb';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Ubicacion extends React.Component {

    constructor(){
        super();
        this.state = {
            geolocalizacion: false,
            latitud: 0,
            longitud: 0
        };
    }

    componentDidMount(){
        this.ObtenerPermisos();
    }

    AccesoDenegado = () => {
        return(
            <React.Fragment>
                <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" style={{"marginTop":"8rem"}}>
                    <AlertTitle>Geolocalizaci&oacute;n Desactivada</AlertTitle>
                    Es necesario que usted active la funci&oacute;n de geolocalizaci&oacute;n en su navegador...
                    <Box textAlign='center' sx={{ m: 2 }}>
                        <Button onClick={this.ObtenerPermisos} variant="contained" size="small" >Comprobar</Button>
                    </Box>
                </Alert>
            </React.Fragment>
        );
    }

    ObtenerPermisos = () => {
        const self = this;
        navigator.geolocation.watchPosition((position) => {
            self.setState({geolocalizacion: true, latitud: position.coords.latitude, longitud: position.coords.longitude});
        },() => {
            self.setState({geolocalizacion: false});
        });
    }

    render(){
        if(this.state.geolocalizacion === true){
            return <CamaraWeb/>
        }else{
            return <this.AccesoDenegado/>
        }
    }
}

export default Ubicacion;