import React,{useState,useEffect} from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import DataTable from 'react-data-table-component';
import {Navigate,useNavigate} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Webcam from "react-webcam";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuConfiguraciones from '../components/MenuConfiguracion';
import withAuth from '../components/withAuth';
import {isAuthenticated} from '../services/Auth';
import FotoCamara from './../logo.svg';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const videoConstraints = {
  width: 1024,
  height: 768,
  facingMode: "user"
};

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const requestOptions = {
  method: 'POST',
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
    'Control-Allow-Origin': '*'
  }
};

const FiltroFecha = (d) => {
  const fecha = d.toUpperCase();
  if(fecha === "INVALID DATE"){
    return "";
  }else{
    return fecha;
  }
};

const MenuEditarUsuario = (props) => {
  const navigate = useNavigate();
  var {row} = props;
  var {Usuario,Empresa,Sucursal,Sexo,TipoIdentificacion,Perfil,Cargo} = row;

  const camaraRef = React.createRef();
  const [baseUrl,setBaseUrl] = useState("");
  const [editarUsuario, setEditarUsuario] = useState(false);
  const [tomarFoto, setTomarFoto] = useState(false);
  const [editadoExitoso, setEditadoExitoso] = useState(false);

  const [empresas, setEmpresas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [tiposIdentificaciones, setTiposIdentificaciones] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sexos, setSexos] = useState([]);
  
  const [empresa, setEmpresa] = useState(Empresa);
  const [sucursal, setSucursal] = useState(Sucursal);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(TipoIdentificacion);
  const [identificacion, setIdentificacion] = useState(Usuario.identificacion);
  const [identificacionExiste, setIdentificacionExiste] = useState(false);
  const [nombres, setNombres] = useState(Usuario.nombres);
  const [apellidos, setApellidos] = useState(Usuario.apellidos);
  const [correo, setCorreo] = useState(Usuario.correo);
  const [clave, setClave] = useState("");
  const [perfil, setPerfil] = useState(Perfil);
  const [cargo, setCargo] = useState(Cargo);
  const [sexo, setSexo] = useState(Sexo);
  const [fechaNacimiento, setFechaNacimiento] = useState(Usuario.fecha_nacimiento);
  const [foto, setFoto] = useState(FotoCamara);

  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    const requestOptions1 = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      }
    };

    const data2 = {
      "nombre_imagen": Usuario.ruta_foto
    };

    const requestOptions2 = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data2)
    };

    fetch(baseUrl+'/api/v1/empresa/obtener/todo', requestOptions1)
    .then(response => response.json())
    .then((data) => {
      setEmpresas(data);
    });
    
    fetch(baseUrl+'/api/v1/usuario/obtener/foto/base64', requestOptions2)
    .then(response => response.json())
    .then((data) => {
      const {imagen_base64} = data;
      setFoto(imagen_base64);
    });

  },[baseUrl]);

  const CambioEmpresa = (event,data) => {
    event.preventDefault();
    const id_empresa = data.id;
    setEmpresa(data);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      }
    };
    fetch(`${baseUrl}/api/v1/sucursal/empresa/obtener/${id_empresa}`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      setSucursales(data);
    });
  };

  const RegistrarEditarUsuario = (e) => {
    e.preventDefault();
    const id_usuario = Usuario.id;
    const id_empresa = empresa.id;
    const id_sucursal = sucursal.id;
    const id_tipo_identificacion = tipoIdentificacion.id;
    const id_cargo = cargo.id;
    const id_sexo = sexo.id;
    const id_perfil = perfil.id;

    const data = {
      "id": id_usuario,
      "id_empresa": id_empresa,
      "id_sucursal": id_sucursal,
      "id_tipo_identificacion": id_tipo_identificacion,
      "id_cargo": id_cargo,
      "id_sexo": id_sexo,
      "id_perfil": id_perfil,
      "nombres": nombres,
      "apellidos": apellidos,
      "correo": correo,
      "clave": clave,
      "identificacion": identificacion,
      "fecha_nacimiento": moment(fechaNacimiento).format("YYYY-MM-DD"),
      "base64_foto": foto,
      "habilitado": true,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    };

    fetch(`${baseUrl}/api/v1/usuario/editar`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      if(data.id_usuario > 0){
        setEditadoExitoso(true);
        setEditarUsuario(false);
      }
    });
  };

  const CambioSucursal = (event,data) => {
    event.preventDefault();
    setSucursal(data);
  };

  const CambioTipoIdentificacion = (event,data) => {
    event.preventDefault();
    setTipoIdentificacion(data);
  };

  const CambioPerfil = (event,data) => {
    event.preventDefault();
    setPerfil(data);
  };

  const CambioCargo = (event,data) => {
    event.preventDefault();
    setCargo(data);
  };

  const CambioSexo = (event,data) => {
    event.preventDefault();
    setSexo(data);
  };

  const CambioIdentificacion = (event) => {
    event.preventDefault();
    const {value} = event.target;
    const id_tipo_identificacion = tipoIdentificacion.id;
    const identificacion = value;

    const data = {
      "id_tipo_identificacion": id_tipo_identificacion,
      "identificacion": identificacion
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    };

    fetch(`${baseUrl}/api/v1/usuario/identificacion/existe`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      const {existe} = data;
      setIdentificacionExiste(existe);
    });
    setIdentificacion(value);
  };

  const CambioNombres = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setNombres(value.toUpperCase());
  };

  const CambioApellidos = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setApellidos(value.toUpperCase());
  };

  const CambioCorreo = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setCorreo(value);
  };

  const CambioClave = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setClave(value);
  };

  const CambioFechaNacimiento = (newValue) => {
    setFechaNacimiento(newValue);
  };

  const AbrirEditarUsuario = (e) => {
    e.preventDefault();
    setEditarUsuario(true);
  };

  const CerrarEditarUsuario = (e) => {
    e.preventDefault();
    setEditarUsuario(false);
  };

  const CerrarTomarFoto = (e) => {
    e.preventDefault();
    setTomarFoto(false);
  };

  const RegistrarNuevaFoto = (e) => {
    e.preventDefault();
    const imagen = camaraRef.current.getScreenshot();
    setFoto(imagen);
    setTomarFoto(false);
  };

  const ValidarFormulario = () => {
    if(
      identificacionExiste === false && 
      empresa !== null &&
      sucursal !== null &&
      tipoIdentificacion !== null &&
      identificacion.length > 0 &&
      perfil !== null &&
      cargo !== null &&
      sexo !== null &&
      fechaNacimiento !== null && 
      nombres.length > 0 &&
      apellidos.length > 0 &&
      correo.length > 0 &&
      clave.length > 0
    ){
      return true;
    }else{
      return false;
    }
  };

  return(
    <div>
      <Button variant="contained" size="small" onClick={AbrirEditarUsuario} color="success"><EditIcon/></Button>
      <Dialog fullScreen open={editarUsuario} onClose={CerrarEditarUsuario} aria-labelledby="editar-usuario" aria-describedby="editar-usuario-description">
        <DialogTitle id="editar-usuario">
          {"EDITAR USUARIO"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{flexGrow:1, mt:1}}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Nombres" variant="outlined" onChange={CambioNombres} value={nombres} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Apellidos" variant="outlined" onChange={CambioApellidos} value={apellidos} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={empresa} onChange={CambioEmpresa} options={empresas} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Empresa" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={sucursal} onChange={CambioSucursal} options={sucursales} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Sucursal" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={tipoIdentificacion} onChange={CambioTipoIdentificacion} options={tiposIdentificaciones} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Tipo Identificación" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Identificación" variant="outlined" onChange={CambioIdentificacion} value={identificacion} disabled={(!tipoIdentificacion) ? true: false}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={perfil} onChange={CambioPerfil} options={perfiles} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Perfil" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={cargo} onChange={CambioCargo} options={cargos} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Cargo" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={sexo} onChange={CambioSexo} options={sexos} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Sexo" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker label="Fecha Nacimiento" inputFormat="dd/MM/yyyy" value={fechaNacimiento} onChange={CambioFechaNacimiento} renderInput={(params) => <TextField {...params} />}/>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Correo" variant="outlined" onChange={CambioCorreo} value={correo} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField type="password" label="Clave" variant="outlined" onChange={CambioClave} value={clave} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Paper variant="outlined">
                  <img alt="perfil" src={foto} width="204.8" height="153.6"/>
                  <Button variant="contained" onClick={() => setTomarFoto(true) } fullWidth>
                    TOMAR FOTO
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={CerrarEditarUsuario}>Cerrar</Button>
          <Button onClick={RegistrarEditarUsuario} disabled={(ValidarFormulario() === true) ? false: true}>Editar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={tomarFoto} onClose={CerrarTomarFoto} aria-labelledby="tomar-foto" aria-describedby="tomar-foto-description">
        <DialogTitle id="nuevo-usuario">
          {"TOMAR FOTO"}
        </DialogTitle>
        <DialogContent>
          <Webcam ref={camaraRef} audio={false} width={409.6} height={307.2} screenshotFormat="image/jpeg" screenshotQuality={1} videoConstraints={videoConstraints}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={CerrarTomarFoto}>Cerrar</Button>
          <Button onClick={RegistrarNuevaFoto}>Tomar Foto</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={identificacionExiste} severity="error" anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={() => setIdentificacionExiste(false)}>
        <Alert severity="error">Ya existe un usuario registrado con este número de identificacion</Alert>
      </Snackbar>
      <Snackbar open={editadoExitoso} severity="error" anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={() => setEditadoExitoso(false)}>
        <Alert severity="success">Los datos del usuario ya fueron actualizados con exito</Alert>
      </Snackbar>
    </div>
  );
};

const MenuEliminarUsuario = (props) => {
  const navigate = useNavigate();
  const [eliminadoExitoso, setEliminadoExitoso] = useState(false);
  const {row} = props;

  const EliminarUsuario = (e) => {
    e.preventDefault();
    setEliminadoExitoso(true);
  }

  return(
    <div>
      <Button variant="contained" size="small" color="error" onClick={EliminarUsuario}><DeleteIcon/></Button>
      <Snackbar open={eliminadoExitoso} severity="error" anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={() => setEliminadoExitoso(false)}>
        <Alert severity="error">La acci&oacute;n no esta disponible por el momento</Alert>
      </Snackbar>
    </div>
  );
};

const columnas = [
  {
    name: <div style={{fontWeight:'bold'}}>ID</div>,
    selector: row => row.Usuario.id,
    width: "50px",
    sortable: true,
    reorder: true,
    button: true,
  },
  {
    name: <div style={{fontWeight:'bold'}}>Nombres</div>,
    selector: row => row.Usuario.nombres,
    sortable: true,
    reorder: true,
    width: "200px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Apellidos</div>,
    selector: row => row.Usuario.apellidos,
    sortable: true,
    reorder: true,
    width: "200px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Identificaci&oacute;n</div>,
    selector: row => row.Usuario.identificacion,
    sortable: true,
    reorder: true,
    width: "100px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Perfil</div>,
    selector: row => row.Perfil.nombre,
    sortable: true,
    reorder: true,
    width: "120px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Sexo</div>,
    selector: row => row.Sexo.nombre,
    sortable: true,
    reorder: true,
    width: "120px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Fecha Creado</div>,
    selector: row => <Moment filter={FiltroFecha} format="DD/MM/YYYY hh:mm:ss">{row.Usuario.fecha_creado}</Moment>,
    sortable: true,
    reorder: true,
    width: "150px",
  },
  {
    name: <div style={{fontWeight:'bold'}}>Fecha Modificado</div>,
    selector: row => <Moment filter={FiltroFecha} format="DD/MM/YYYY hh:mm:ss">{row.Usuario.fecha_modificado}</Moment>,
    sortable: true,
    reorder: true,
    width: "150px",
  },
  {
    cell: row => <MenuEditarUsuario row={row} />,
    width: "auto"
  },
  {
    cell: row => <MenuEliminarUsuario row={row} />,
    width: "auto"
  }
];

const EncabezadoTabla = () => {
  const camaraRef = React.createRef();
  const [baseUrl,setBaseUrl] = useState("");
  const [nuevoUsuario, setNuevoUsuario] = useState(false);
  const [tomarFoto, setTomarFoto] = useState(false);
  const [creadoExitoso, setCreadoExitoso] = useState(false);

  const [datos, setDatos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [tiposIdentificaciones, setTiposIdentificaciones] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sexos, setSexos] = useState([]);
  
  const [empresa, setEmpresa] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(null);
  const [identificacion, setIdentificacion] = useState("");
  const [identificacionExiste, setIdentificacionExiste] = useState(false);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [correo, setCorreo] = useState("");
  const [clave, setClave] = useState("");
  const [perfil, setPerfil] = useState(null);
  const [cargo, setCargo] = useState(null);
  const [sexo, setSexo] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [foto, setFoto] = useState(FotoCamara);

  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      }
    };
    
    fetch(baseUrl+'/api/v1/usuario/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setDatos(data);
    });

    fetch(baseUrl+'/api/v1/tipo/identificacion/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setTiposIdentificaciones(data);
    });

    fetch(baseUrl+'/api/v1/perfil/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setPerfiles(data);
    });

    fetch(baseUrl+'/api/v1/cargo/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setCargos(data);
    });

    fetch(baseUrl+'/api/v1/sexo/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setSexos(data);
    });
  },[baseUrl]);

  const ValidarFormulario = () => {
    if(
      identificacionExiste === false && 
      empresa !== null &&
      sucursal !== null &&
      tipoIdentificacion !== null &&
      identificacion.length > 0 &&
      perfil !== null &&
      cargo !== null &&
      sexo !== null &&
      fechaNacimiento !== null && 
      nombres.length > 0 &&
      apellidos.length > 0 &&
      correo.length > 0 &&
      clave.length > 0
    ){
      return true;
    }else{
      return false;
    }
  };

  const RegistrarNuevaFoto = (e) => {
    e.preventDefault();
    const imagen = camaraRef.current.getScreenshot();
    setFoto(imagen);
    setTomarFoto(false);
  };

  const AbrirNuevoUsuario = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      }
    };
    fetch(baseUrl+'/api/v1/empresa/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setEmpresas(data);
    });
    setNuevoUsuario(true);
  };

  const CambioEmpresa = (event,data) => {
    event.preventDefault();
    const id_empresa = data.id;
    setEmpresa(data);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      }
    };
    fetch(`${baseUrl}/api/v1/sucursal/empresa/obtener/${id_empresa}`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      setSucursales(data);
    });
  };

  const RegistrarNuevoUsuario = (e) => {
    e.preventDefault();
    const id_empresa = empresa.id;
    const id_sucursal = sucursal.id;
    const id_tipo_identificacion = tipoIdentificacion.id;
    const id_cargo = cargo.id;
    const id_sexo = sexo.id;
    const id_perfil = perfil.id;

    const data = {
      "id_empresa": id_empresa,
      "id_sucursal": id_sucursal,
      "id_tipo_identificacion": id_tipo_identificacion,
      "id_cargo": id_cargo,
      "id_sexo": id_sexo,
      "id_perfil": id_perfil,
      "nombres": nombres,
      "apellidos": apellidos,
      "correo": correo,
      "clave": clave,
      "identificacion": identificacion,
      "fecha_nacimiento": moment(fechaNacimiento).format("YYYY-MM-DD"),
      "base64_foto": foto,
      "habilitado": true,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    };

    fetch(`${baseUrl}/api/v1/usuario/agregar`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      if(data.id_usuario > 0){
        BorrarDatos();
        setCreadoExitoso(true);
      }
    });
  };

  const CambioSucursal = (event,data) => {
    event.preventDefault();
    setSucursal(data);
  };

  const CambioTipoIdentificacion = (event,data) => {
    event.preventDefault();
    setTipoIdentificacion(data);
  };

  const CambioPerfil = (event,data) => {
    event.preventDefault();
    setPerfil(data);
  };

  const CambioCargo = (event,data) => {
    event.preventDefault();
    setCargo(data);
  };

  const CambioSexo = (event,data) => {
    event.preventDefault();
    setSexo(data);
  };

  const CambioIdentificacion = (event) => {
    event.preventDefault();
    const {value} = event.target;
    const id_tipo_identificacion = tipoIdentificacion.id;
    const identificacion = value;

    const data = {
      "id_tipo_identificacion": id_tipo_identificacion,
      "identificacion": identificacion
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    };

    fetch(`${baseUrl}/api/v1/usuario/identificacion/existe`, requestOptions)
    .then(response => response.json())
    .then((data) => {
      const {existe} = data;
      setIdentificacionExiste(existe);
    });
    setIdentificacion(value);
  };

  const CambioNombres = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setNombres(value.toUpperCase());
  };

  const CambioApellidos = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setApellidos(value.toUpperCase());
  };

  const CambioCorreo = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setCorreo(value);
  };

  const CambioClave = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setClave(value);
  };

  const CambioFechaNacimiento = (newValue) => {
    setFechaNacimiento(newValue);
  };

  const CerrarNuevoUsuario = (e) => {
    e.preventDefault();
    setNuevoUsuario(false);
  };

  const CerrarTomarFoto = (e) => {
    e.preventDefault();
    setTomarFoto(false);
  };

  const BorrarDatos = () => {
    setEmpresa(null);
    setSucursal(null);
    setTipoIdentificacion(null);
    setIdentificacion("");
    setNombres("");
    setApellidos("");
    setCorreo("");
    setClave("");
    setPerfil(null);
    setCargo(null);
    setSexo(null);
    setFechaNacimiento(null);
    setFoto(foto);
  };

  return(
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={AbrirNuevoUsuario} fullWidth>
            NUEVO
          </Button>
        </Grid>
      </Grid>
      <Dialog fullScreen open={nuevoUsuario} onClose={CerrarNuevoUsuario} aria-labelledby="nuevo-usuario" aria-describedby="nuevo-usuario-description">
        <DialogTitle id="nuevo-usuario">
          {"CREAR NUEVO USUARIO"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{flexGrow:1, mt:1}}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Nombres" variant="outlined" onChange={CambioNombres} value={nombres} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Apellidos" variant="outlined" onChange={CambioApellidos} value={apellidos} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={empresa} onChange={CambioEmpresa} options={empresas} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Empresa" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={sucursal} onChange={CambioSucursal} options={sucursales} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Sucursal" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={tipoIdentificacion} onChange={CambioTipoIdentificacion} options={tiposIdentificaciones} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Tipo Identificación" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Identificación" variant="outlined" onChange={CambioIdentificacion} value={identificacion} disabled={(!tipoIdentificacion) ? true: false}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={perfil} onChange={CambioPerfil} options={perfiles} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Perfil" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={cargo} onChange={CambioCargo} options={cargos} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Cargo" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete disablePortal value={sexo} onChange={CambioSexo} options={sexos} getOptionLabel={(option) => `${option.nombre}`} renderInput={(params) => <TextField {...params} label="Sexo" />}/>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker label="Fecha Nacimiento" inputFormat="dd/MM/yyyy" value={fechaNacimiento} onChange={CambioFechaNacimiento} renderInput={(params) => <TextField {...params} />}/>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField label="Correo" variant="outlined" onChange={CambioCorreo} value={correo} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField type="password" label="Clave" variant="outlined" onChange={CambioClave} value={clave} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Paper variant="outlined">
                  <img alt="perfil" src={foto} width="204.8" height="153.6"/>
                  <Button variant="contained" onClick={() => setTomarFoto(true) } fullWidth>
                    TOMAR FOTO
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={CerrarNuevoUsuario}>Cerrar</Button>
          <Button onClick={RegistrarNuevoUsuario} disabled={(ValidarFormulario() === true) ? false: true}>Agregar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={tomarFoto} onClose={CerrarTomarFoto} aria-labelledby="tomar-foto" aria-describedby="tomar-foto-description">
        <DialogTitle id="nuevo-usuario">
          {"TOMAR FOTO"}
        </DialogTitle>
        <DialogContent>
          <Webcam ref={camaraRef} audio={false} width={409.6} height={307.2} screenshotFormat="image/jpeg" screenshotQuality={1} videoConstraints={videoConstraints}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={CerrarTomarFoto}>Cerrar</Button>
          <Button onClick={RegistrarNuevaFoto}>Tomar Foto</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={identificacionExiste} severity="error" anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={() => setIdentificacionExiste(false)}>
        <Alert severity="error">Ya existe un usuario registrado con este número de identificacion</Alert>
      </Snackbar>
      <Snackbar open={creadoExitoso} severity="error" anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={() => setCreadoExitoso(false)}>
        <Alert severity="success">El usuario fue creado con exito</Alert>
      </Snackbar>
    </>
  );
};

const Usuarios = () => {
  const [baseUrl,setBaseUrl] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    fetch(baseUrl+'/api/v1/usuario/obtener/todo', requestOptions)
    .then(response => response.json())
    .then((data) => {
      setUsuarios(data);
    });
  },[baseUrl]);

  if (isAuthenticated() === false) return <Navigate to="/iniciar/sesion" />;
  
  return(
  <div className="container">
    <MenuConfiguraciones titulo="CONFIGURACIONES"/>
    <Box sx={{width:"100%",mt:5.5}}>
      <DataTable title="Usuarios" columns={columnas} data={usuarios} paginationRowsPerPageOptions={[50, 100, 150, 200]} paginationComponentOptions={paginationComponentOptions} pagination subHeader subHeaderComponent={<EncabezadoTabla/>} persistTableHead/>
    </Box>
  </div>
  ); 
};

export default withAuth(Usuarios);