export const isBrowser = () => typeof window !== "undefined";
export const getUser = () => isBrowser() && window.localStorage.getItem("biometricoUser") ? JSON.parse(window.localStorage.getItem("biometricoUser")): {}
const setUser = user => window.localStorage.setItem("biometricoUser", JSON.stringify(user));

export const setSessionData = (id,nombres,apellidos,correo) => {
    return setUser({id,nombres,apellidos,correo});
}

export const isAuthenticated = () => {
    const user = getUser();
    return !!user.correo;
}

export const logout = callback => {
    setUser({});
    localStorage.removeItem("biometricoUser");
    callback();
}