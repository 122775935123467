import React from 'react';
import Ubicacion from './components/Ubicacion';

const Home = () => {
  return (
    <div className="container">
        <Ubicacion>
        </Ubicacion>
    </div>
  );
};

export default Home;