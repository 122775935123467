import React from "react";
import Webcam from "react-webcam";
import Clock from "react-live-clock";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Snackbar from '@mui/material/Snackbar';
import {faDoorOpen,faDoorClosed,faCouch} from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import MuiAlert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import Box from '@mui/material/Box';
import MenuHorizontal from "./MenuHorizontal";
import {BrowserView, MobileView} from "react-device-detect";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from '@mui/material/Backdrop';
import { CountertopsOutlined } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const videoConstraints = {
  width: 800,
  height: 600,
  facingMode: "user"
};

class CamaraWeb extends React.Component {

    constructor(){
        super();
        this.camaraRef = React.createRef();
        this.state = {
          baseUrl: "",
          cargando:false,
          //urlIdentificarRostro: "https://api-biometrico.sisconti.com/api/v1/biometrico/identificar/rostro",
          video: false,
          zonaHoraria: "America/Guayaquil",
          rostroIdentificado: false,
          rostroMensaje: false,
          rostroSeverity: "success",
          posicion: {
            vertical: "top",
            horizontal: "center"
          },
          localizacion:{
            latitud: 0,
            longitud: 0
          }
        };
    }

    componentDidMount(){
      this.setState({
        baseUrl: process.env.REACT_APP_BASE_URL
      });
      this.ObtenerPermisos();
    }

    ObtenerLocalizacion = () => {
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          function(position){
            const {latitude, longitude} = position.coords;
            resolve({latitude, longitude});
          },
          function(error){
            resolve(error)
          }, 
          {
            timeout:5000,
            enableHighAccuracy:true,
            maximumAge:1000,
          }
        );
      });
    }

    ObtenerPermisos = () => {
      const self = this;
      navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
        if (stream.getVideoTracks().length > 0){
          self.setState({video: true});
        } else {
          self.setState({video: false});
        }
      }).catch(() => {
        self.setState({video: false});
      });
    }

    TomarFotoEntrada = (e) => {
      e.preventDefault();
      const self = this;
      self.setState({cargando: true});
      const imagen = this.camaraRef.current.getScreenshot();
      const data1 = {
        "imagen_base64": imagen
      };
      const peticion1 = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data1)
      };
      fetch(this.state.baseUrl+'/api/v1/biometrico/identificar/rostro', peticion1)
      .then(response => response.json())
      .then((data) => {
        const {resultado,mensaje,id_usuario} = data;
        self.ObtenerLocalizacion().then((geo) => {
          const {latitude, longitude} = geo;
          if(resultado === true){
            const data2 = {
              id_usuario: id_usuario,
              coordenadas: `${latitude};${longitude}`
            };
            const peticion2 = {
              method: 'POST',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Control-Allow-Origin': '*'
              },
              body: JSON.stringify(data2)
            };
            fetch(this.state.baseUrl+'/api/v1/asistencia/entrada/realizar', peticion2)
            .then(response => response.json())
            .then((data) => {
              self.setState({
                rostroIdentificado: true,
                rostroMensaje: mensaje,
                rostroSeverity: "success",
                cargando: false
              });
            });
          }else{
            self.setState({
              rostroIdentificado: true,
              rostroMensaje: mensaje,
              rostroSeverity: "error",
              cargando: false
            });
          }
        });
      });
    }

    TomarFotoSalida = (e) => {
      e.preventDefault();
      const self = this;
      self.setState({cargando: true});
      const imagen = this.camaraRef.current.getScreenshot();
      const data1 = {
        "imagen_base64": imagen
      };
      const peticion1 = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data1)
      };

      fetch(this.state.baseUrl+'/api/v1/biometrico/identificar/rostro', peticion1)
      .then(response => response.json())
      .then((data) => {
        const {resultado,mensaje,id_usuario} = data;
        self.ObtenerLocalizacion().then((geo) => {
          const {latitude, longitude} = geo;
          if(resultado === true){
            const data2 = {
              id_usuario: id_usuario,
              coordenadas: `${latitude};${longitude}`
            };
            const peticion2 = {
              method: 'POST',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Control-Allow-Origin': '*'
              },
              body: JSON.stringify(data2)
            };
            fetch(this.state.baseUrl+'/api/v1/asistencia/salida/realizar', peticion2)
            .then(response => response.json())
            .then((data) => {
              self.setState({
                rostroIdentificado: true,
                rostroMensaje: mensaje,
                rostroSeverity: "success",
                cargando: false
              });
            });
          }else{
            self.setState({
              rostroIdentificado: true,
              rostroMensaje: mensaje,
              rostroSeverity: "error",
              cargando: false
            });
          }
        });
      });
    }

    TomarFotoReceso = (e) => {
      e.preventDefault();
      const self = this;
      self.setState({cargando: true});
      const imagen = this.camaraRef.current.getScreenshot();
      const data1 = {
        "imagen_base64": imagen
      };
      const peticion1 = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data1)
      };
      fetch(this.state.baseUrl+'/api/v1/biometrico/identificar/rostro', peticion1)
      .then(response => response.json())
      .then((data) => {
        const {resultado,mensaje,id_usuario} = data;
        self.ObtenerLocalizacion().then((geo) => {
          const {latitude, longitude} = geo;
          if(resultado === true){
            const data2 = {
              id_usuario: id_usuario,
              coordenadas: `${latitude};${longitude}`
            };
            const peticion2 = {
              method: 'POST',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Control-Allow-Origin': '*'
              },
              body: JSON.stringify(data2)
            };
            fetch(this.state.baseUrl+'/api/v1/asistencia/break/realizar', peticion2)
            .then(response => response.json())
            .then((data) => {
              self.setState({
                rostroIdentificado: true,
                rostroMensaje: mensaje,
                rostroSeverity: "success",
                cargando: false
              });
            });
          }else{
            self.setState({
              rostroIdentificado: true,
              rostroMensaje: mensaje,
              rostroSeverity: "error",
              cargando: false
            });
          }
        });
      });
    }

    CerrarRostroIdentificado = () => {
      this.setState({
        rostroIdentificado: false,
        rostroMensaje: "",
        rostroSeverity: "success"
      });
    };

    AbrirCargando = () => {
      this.setState({cargando: true});
    }

    CerrarCargando = () => {
      this.setState({cargando: false});
    }

    render(){
      if(this.state.video === true){
        return(
          <React.Fragment>
            {(this.state.cargando === true) && <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.AbrirCargando}
            >
              <CircularProgress color="inherit" />
            </Backdrop>}
            <Snackbar
              open={this.state.rostroIdentificado}
              onClose={this.CerrarRostroIdentificado}
              autoHideDuration={6000}
              anchorOrigin={this.state.posicion}>
                <Alert severity={this.state.rostroSeverity} sx={{width: '100%'}}>
                  {this.state.rostroMensaje}
                </Alert>
            </Snackbar>
            <BrowserView>
              <MenuHorizontal/>
              <Box component="span" sx={{width: 300, height: 300}} align="center">
                <Webcam className="Camara" ref={this.camaraRef} audio={false} width={400} height={400} screenshotFormat="image/jpeg" screenshotQuality={1} videoConstraints={videoConstraints}/>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography align="center">
                      <Clock format="HH:mm:ss" interval={1000} ticking={true} timezone={this.state.zonaHoraria} className="Reloj"/>
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={2.5}>
                      <Button color="success" onClick={this.TomarFotoEntrada} variant="contained" size="large" fullWidth={true} startIcon={<FontAwesomeIcon icon={faDoorOpen} size="lg" />}>ENTRADA</Button>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Button color="error" onClick={this.TomarFotoSalida} variant="contained" size="large" fullWidth={true} startIcon={<FontAwesomeIcon icon={faDoorClosed} size="lg" />}>SALIDA</Button>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Button color="warning" onClick={this.TomarFotoReceso} variant="contained" size="large" fullWidth={true} startIcon={<FontAwesomeIcon icon={faCouch} size="lg" />}>BREAK</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </BrowserView>
            <MobileView>
              <Box component="span" sx={{width: 300, height: 300}} align="center">
                <Typography variant="h6" align="center">
                  Control de Asistencia
                </Typography>
                <Typography variant="h6" align="center">
                <Webcam ref={this.camaraRef} audio={false} width={350} height={300} screenshotFormat="image/jpeg" screenshotQuality={1} videoConstraints={videoConstraints}/>
                  <Clock format="HH:mm:ss" interval={1000} ticking={true}/>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Button onClick={this.TomarFotoEntrada} variant="contained" size="large" fullWidth={true}>Entrada</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={this.TomarFotoSalida} variant="contained" size="large" fullWidth={true}>Salida</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={this.TomarFotoReceso} variant="contained" size="large" fullWidth={true}>Receso</Button>
                  </Grid>
                </Grid>
              </Box>
            </MobileView>
          </React.Fragment>
        );
      }else{
        return(
        <React.Fragment>
          <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" style={{"marginTop":"8rem"}}>
              <AlertTitle>C&aacute;mara Requerida</AlertTitle>
              Es necesario que usted disponga de una c&aacute;mara y habilite los permisos en su navegador...
              <Box textAlign='center' sx={{ m: 2 }}>
                  <Button onClick={this.ObtenerPermisos} variant="contained" size="small" >Comprobar</Button>
              </Box>
          </Alert>
        </React.Fragment>
        );
      }
    }
}

export default CamaraWeb;