import React,{useState,useEffect} from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import {Navigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Webcam from "react-webcam";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MenuConfiguraciones from '../components/MenuConfiguracion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import withAuth from '../components/withAuth';
import {isAuthenticated} from '../services/Auth';

const Sucursales = () => {
  const camaraRef = React.createRef();
  const [baseUrl,setBaseUrl] = useState("");
  const [nuevoUsuario, setNuevoUsuario] = useState(false);
  const [tomarFoto, setTomarFoto] = useState(false);

  const [datos, setDatos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [tiposIdentificaciones, setTiposIdentificaciones] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sexos, setSexos] = useState([]);
  
  const [empresa, setEmpresa] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(null);
  const [identificacion, setIdentificacion] = useState("");
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [correo, setCorreo] = useState("");
  const [clave, setClave] = useState("");
  const [perfil, setPerfil] = useState(null);
  const [cargo, setCargo] = useState(null);
  const [sexo, setSexo] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);

  if (isAuthenticated() === false) return <Navigate to="/iniciar/sesion" />;

  return(
  <div className="container">
    <MenuConfiguraciones titulo="SUCURSALES"/>
    <Box sx={{width:"100%",mt:5.5}}>

    </Box>
  </div>
  );

};

export default withAuth(Sucursales);