import React,{useState,useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {setSessionData} from './services/Auth';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  contenedor:{
    position: "relative",
    minHeight: "100vh"
  },
  centrado:{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "2em",
    borderRadius: "2em"
  },
  boton:{
    marginTop: "2em"
  }
});

const IniciarSesion = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [baseUrl, setBaseUrl] = useState('');
  const [dialogo, setDialogo] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => { 
    setBaseUrl(process.env.REACT_APP_BASE_URL);
  });

  const EnviarSolicitud = (e) => {
    e.preventDefault();
    const data = {"correo": email,"clave": password};
    const requestOptions = {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data)
    };

    fetch(baseUrl+'/api/v1/usuario/iniciar/sesion', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data !== null){
        if(data.Perfil.nombre === "ADMINISTRADOR"){
          setDialogo(true);
          setEmail("");
          setPassword("");
          /*
          if(data.Sexo.nombre === "MASCULINO"){
            setMensaje(`Bienvenido ${data.Usuario.nombres} ${data.Usuario.apellidos}`);
          }else{
            setMensaje(`Bienvenida ${data.Usuario.nombres} ${data.Usuario.apellidos}`);
          }*/
          setSessionData(
            data.Usuario.id,
            data.Usuario.nombres,
            data.Usuario.apellidos,
            data.Usuario.correo
          );
          //setSeverity("success");
          navigate("/configuracion");
        }else{
          setDialogo(true);
          setEmail("");
          setPassword("");
          if(data.Sexo.nombre === "MASCULINO"){
            setMensaje(`Estimado ${data.Usuario.nombres}, no tiene privilegios suficientes para acceder a este sitio.`);
          }else{
            setMensaje(`Estimada ${data.Usuario.nombres}, no tiene privilegios suficientes para acceder a este sitio.`);
          }
          setSeverity("warning");
        }
      }else{
        setDialogo(true);
        setEmail("");
        setPassword("");
        setSeverity("error");
        setMensaje("Sus credenciales no son válidas");
      }
    }).catch(error => console.error('Error:', error));
  }

  const CerrarDialogo = () => {
    setDialogo(false);
  }

  return (
    <div className={classes.contenedor}>
      <form className={classes.centrado} onSubmit={EnviarSolicitud}>
        <TextField label="Correo Electrónico" variant="filled" type="email" value={email} onChange={e => setEmail(e.target.value)} fullWidth/>
        <TextField label="Contraseña" variant="filled" type="password" value={password} onChange={e => setPassword(e.target.value)} fullWidth/>
        <div className={classes.boton}>
          <Button type="submit" variant="contained" color="primary">
            Iniciar Sesi&oacute;n
          </Button>
        </div>
      </form>
      <Snackbar open={dialogo} autoHideDuration={6000} onClose={CerrarDialogo} anchorOrigin={{vertical:"top", horizontal:"center"}}>
        <Alert onClose={CerrarDialogo} severity={severity} sx={{width: '100%'}}>
          {mensaje}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default IniciarSesion;