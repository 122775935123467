import React from 'react';

const NuevoUsuario = () => {
  return (
    <div className="container">
        Nuevo Usuario!
    </div>
  );
};

export default NuevoUsuario;